export const speciesMenuSkeleton = [
	{
		"apiName": "mammals",
		"menuTitle": "Mammals",
		"menuTitleEs": "Mamiferos",
		"icon": "mammals",
		"bg": "/images/mammals.jpg"
	},
	{
		"apiName": "birds",
		"menuTitle": "Birds",
		"menuTitleEs": "Aves",
		"icon": "birds",
		"bg": "/images/birds.jpg"
	},
	{
		"apiName": "reptiles-amphibians",
		"menuTitle": "Reptiles / Amphibians",
		"menuTitleEs": "Reptiles / Anfibios",
		"icon": "reptiles",
		"bg": "/images/reptiles-amphibians.jpg"
	},
	{
		"apiName": "fish",
		"menuTitle": "Sharks, Rays",
		"menuTitleEs": "Tiburones, Rayas",
		"icon": "fish",
		"bg": "/images/fish.jpg"
	},
	{
	"apiName": "taxidermy",
	"menuTitle": "Taxidermy",
	"menuTitleEs": "Taxidermia",
	"icon": "fish",
	"bg": "/images/Edu_pic_m1.jpg",	
	},
	{
		"apiName": "all",
		"menuTitle": "All",
		"menuTitleEs": "Todas",
		"icon": "all",
		"bg": "/images/tijger.jpg"
	}
]

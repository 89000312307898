import React, {useState,useRef,useEffect} from 'react'
import {stuffedAnimalsList} from '../data/stuffedAnimals'
import ImageWithTitle from '../components/ImageWithTitle'
import Button from '../components/Button'

import PageHeader from '../components/PageHeader'
import Row from '../components/Row'
import Column from '../components/Column'

import {en, es} from '../languages/langs'
import {countryCodes} from '../data/countryCodes'
import {api} from '../services/api'


const StuffedAnimals = ({activeLang}) => {
    const lang = {en, es}[activeLang]
	const fullnameRef = useRef(null);

	const renderedAnimals = stuffedAnimalsList.map((mainEntry, i) => {
		return (
			<Column extraClasses="size__one-of-five p-right__x-large" key={i}>
				<ImageWithTitle 
					image={mainEntry.bg}
					activeLang={activeLang}
					text={mainEntry.menuTitle}
					spanishText={mainEntry.menuTitleEs}
				/>
			</Column>
		)
	
})



	const renderedCountrySelect = []
	for (let code in countryCodes) {
		renderedCountrySelect.push(<option key={code} value={code}>{countryCodes[code]}</option>)
	}

	async function SendEnquiry() {
		//const country         = [...document.getElementById('register-form__country').children].filter(opt => opt.selected)[0].value
		const country = [...document.getElementById('register-form__country').getElementsByTagName('option')].find(opt => opt.selected).innerText


		const fields          =[...document.getElementById(`contact-form`).getElementsByTagName('INPUT')].map(input => {
			return {
				"type"      : input.type,
				"mandatory" : input.classList.contains('register-form__input--mandatory'),
				"name"      : input.id,
				"checked"   : input.checked,
				"ref"       : input
			}
		});

		console.log('country ',country);

			if (country === 'Country') {
				document.getElementById('register-form__country').classList.add('register-form__input--has-error')
			}
			else {
				document.getElementById('register-form__country').classList.remove('register-form__input--has-error')
			}
		
	
		const errorFields = fields.filter(field => {
			if ((field.type === 'text' ) && field.mandatory && field.ref.value === '') {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}

			if (field.type === 'email'
				&& field.mandatory
				&& (field.ref.value === ''
					|| !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field.ref.value)
				)
			) {
				field.ref.classList.add('register-form__input--has-error')
				return field
			} else {
				field.ref.classList.remove('register-form__input--has-error')
			}
		})

		if (errorFields.length > 0 || country === 'Country' ) {
			alert(lang['mand_info_missing'])
			return
		}
		const salutation = [...document.getElementById('register-form__salutation').children].filter(opt => opt.selected)[0].value

		let fname = salutation + fields.find(field => field.name === 'register-form--fname').ref.value;
		let msg = "Please send me a list of your taxidermy specimens.";

		const application = {
			"name"                : fname,
			"email"                     : fields.find(field => field.name === 'register-form--email').ref.value,
			"country"                   : country,
			"city"                      : fields.find(field => field.name === 'register-form--city').ref.value,
			"message" : msg
		};
		console.log('jothi sending ', application);
		try {
			const response = await api.post(
				'/contact-us-stuffed-info',
				application
			)
			console.log('server response ', response);

			if (response.data.success === true) {
				alert('Enquiry is sent successfully')
				return
			}
			if (response.data.success === false) {
				alert(response.data.message)
				return
			}
		} catch (e) {
			console.log(e)
			alert('Some error occurred')

		}
		
	}



	return (
        <React.Fragment>
			
            <PageHeader titleKey="Stuffed Animals" image="images/papegaai.jpg" bgPos="0% 50%" activeLang={activeLang} />		
         
		    <section className='content-width center'>
			
			<Row>
			<Column id="contact-form" extraClasses="size__two-of-three p-top__x-large">
			<p className='stuffed-text '>
                <span dangerouslySetInnerHTML={{__html: lang['Stuffed we support']}} />
            </p> 
			<Row extraClasses='size__one-of-one'>
				<p className='title'>Enquiry Form</p>
			</Row>

			<Row extraClasses='size__one-of-one m-top__medium'>
											<Column extraClasses="size__two-of-twelve">
												<select name="register-form__salutation" id="register-form__salutation" className="register-form__salutation select--creme">
													<option value="Mr.">Mr.</option>
													<option value="Mrs.">Mrs.</option>
													<option value="Ms.">Ms.</option>
													<option value="Dr.">Dr.</option>
													<option value="Ing.">Ing.</option>
												</select>
											</Column>
											<Column extraClasses="size__ten-of-twelve p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text"
													placeholder={`${lang['Full Name']}*`}
													className="register-form__input register-form__input--mandatory"
													id="register-form--fname"
													ref={fullnameRef}
												/>
											</Column>
			</Row>
			<Row extraClasses='m-top__medium'>
											
												<input
													type="email"
													placeholder={`${lang['Email address']}*`}
													className="register-form__input register-form__input--mandatory"
													id="register-form--email"
												/>
			</Row>
			<Row extraClasses="m-top__large m-bottom__large">
											<Column extraClasses="size__one-of-two">
												<select name="register-form__country" id="register-form__country" className="register-form__country select--creme">
													<option key="empty" value="empty">{`${lang['Country']}`}</option>
													{renderedCountrySelect}
												</select>
											</Column>
											<Column extraClasses="size__one-of-two p-left__medium mobile-p-left__none mobile-m-top__medium">
												<input
													type="text" 
													className="register-form__input register-form__input--mandatory"
													placeholder={`${lang['City']}`}
													id="register-form--city"
												/>
											</Column>
			</Row>
			<Row>
				<Button key="submit" type="submit" onClick={() => SendEnquiry()} >{lang['Submit']}</Button>
			</Row>

			</Column>
			<Column extraClasses="size__one-of-three p-left__medium mobile-p-right__none mobile-p-left__none">
						<div className="framed-image framed-image--xx-largeheight m-top__medium pull-up--mobile-none">
							<div className="framed-image__frame">
								<div className="framed-image__image" style={{backgroundImage: "url('images/two_owl_icon.jpg')"}}></div>
							</div>
						</div>
			</Column>
				
			
			</Row>
			</section>
            <Row extraClasses="p-top__large p-bottom__large content-width center mobile-layout--hor-scroll">
					{renderedAnimals }
			</Row>	
             

        </React.Fragment>
    )
}

export default StuffedAnimals
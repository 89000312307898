
import React from 'react'
import PageHeader from '../components/PageHeader'
import {en, es} from '../languages/langs'
import Column from '../components/Column'
import Row from '../components/Row'
import CheckAndText from '../components/CheckAndText'

const ZooManagement = ({activeLang}) => {
    const lang = {en, es}[activeLang]

    return (
        <React.Fragment>
            <PageHeader titleKey="Zoo management" image="images/papegaai.jpg" bgPos="60% 50%" activeLang={activeLang} />
            <div className='content-width center'>
                <p className='stuffed-text'>
                    {lang['our partners']}
                </p>
                <Row extraClasses='size__one-of-one'>
                    <Column extraClasses='size__one-of-two'>
                        <CheckAndText activeLang={activeLang} text="Feeding"/>
                        <CheckAndText activeLang={activeLang} text="Husbandry"/>
                        <CheckAndText activeLang={activeLang} text="Transport"/>         
                    </Column>
                    <Column extraClasses='size__one-of-two'>
                        <CheckAndText activeLang={activeLang} text="Educational material"/>
                        <CheckAndText activeLang={activeLang} text="Zoo designers"/>
                        <CheckAndText activeLang={activeLang} text="books"/>
                    </Column>
                </Row>
            </div>
            
        </React.Fragment>
    )
}
export default ZooManagement
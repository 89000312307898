import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import PageHeader from '../components/PageHeader'
import Row from '../components/Row'
import Column from '../components/Column'

import {speciesMenuSkeleton} from '../data/speciesMenuSkeleton'
import {en, es} from '../languages/langs'
import {api, base} from '../services/api'
import {primates_links} from '../data/PrimatesInfo'

const MiniCatalog = ({activeLang, orderNames, route, updateActiveRoute}) => {
	
	const lang = {en, es}[activeLang]


	const [pageHeaderInfo, setPageHeaderInfo] = useState({"icon": "", "title": "", "bg": ""})


	const getInfoText = () => {
		console.log('jothi infotext  ')

		if (activeLang === 'en') {
			return 'There might be several ' + pageHeaderInfo.title.toLowerCase() + ' available of the different families. To view complete inventory of this category, please click <a href="/login">Here</a> to login if you are a registered user. If not yet registered: please register <a href="/register">Here</a>'
		} else {
			return 'Puede haber varios ' + pageHeaderInfo.title.toLowerCase() + ' disponibles de las diferentes familias. Para ver el inventario completo de esta categoría, haga clic <a href="/login">aquí</a> para iniciar sesión si es un usuario registrado. Si aún no está registrado: regístrese <a href="/register">aquí</a>'
		}
	}

	const getLinksInfo = () => {

		if (activeLang === 'en') {
			return 'For information about exhibition, maintenance and husbandry, the following partners can be recommended:'
		} else {
			return 'Para obtener información sobre exposición, mantenimiento y cría, se pueden recomendar los siguientes socios:'
		}
	}

	

	useEffect(() => {	
	
		const slug = window.location.pathname.substring(1)
		const slugParts = slug.split('/')
		
		const menuItem = speciesMenuSkeleton.find(item => item.apiName === slugParts[0])
		let thisEntry = {}

		const renderPageHeader = async () => {
			{/*no order then get from server */}
			if (orderNames[slugParts[1]] === undefined) {
				const response = await api.get(`/get-class-menu?class=${slugParts[0]}`)
				const entries = response.data
				thisEntry = entries.find(entry => {
					return entry.common_name_slug === slugParts[1]
				})
			} else {
				thisEntry = orderNames[slugParts[1]]
			}

			thisEntry = thisEntry === undefined ? menuItem : thisEntry

			setPageHeaderInfo({
				"className": activeLang === 'en' ? menuItem.menuTitle : menuItem.menuTitleEs,
				"title": activeLang === 'en' ? thisEntry.common_name || thisEntry.menuTitle : thisEntry.common_name_spanish || thisEntry.menuTitleEs,
				"icon": slugParts[0],
				"bg": menuItem.bg,
				"classLink": slugParts[0]
			})
		}
		renderPageHeader()
		window.scrollTo(0,0); 
	}, [activeLang, route, orderNames])



	//related links
	const relatedLinks = primates_links.map((category, index) => {
		const title = lang[category.title]

		const subLinks = category.links.map(subcategory => {
				return (
					<Column extraClasses='brand-image'>

						<Link to={{pathname : subcategory.web_url}} target="_blank" className="image-button">
								<div className="square-container brand__container">
									<div
										className="square-container brand__container--img"
										style={{backgroundImage: `url('${subcategory.ipath}')`}}
									>
									</div>
									<div className="brand__container--arrow green-background"></div>
								</div>
							</Link>




					</Column>
				)
		})
		return (
				<Column extraClasses="related-info-div">
					<p className='category-title white-text'>{title}</p>
					<div className='image-row'>
						{subLinks}
					</div>
				</Column>
		)
		
	})



	return (
		<React.Fragment>
			
			<PageHeader
				showTitle={false}
				image={`https://${base}/header_images${window.location.pathname}.jpg`}
				bgPos="50% 50%"
				highVersion={true}
				extraClasses="content-width center p-left__x-large"
			>
				<div className="page-image-header__title content-width center p-top__x-large text-shadow--large">
					<h1 className="creme-text m-bottom__none">{pageHeaderInfo.title}</h1> 
				</div>
			
			</PageHeader>
            <p className='stuffed-text content-width center'>
                <span dangerouslySetInnerHTML={{__html: getInfoText()}} />
            </p> 
			<p className='stuffed-text content-width center'>
                <span dangerouslySetInnerHTML={{__html: getLinksInfo()}} />
            </p>
			<Row extraClasses="image-section p-bottom__large content-width center mobile-layout--hor-scroll">
					{relatedLinks }
			</Row>	
			
		</React.Fragment>
	)
}

export default MiniCatalog
import React from 'react'
import {useState} from 'react'
import { Link } from 'react-router-dom'
import GuestMenuComponent from './GuestMenuComponent'

const ImageText = props => {

	
	const getText = () => {
		
		if (props.activeLang === 'en') {
			return props.mainMenu.menuTitle
		} else {
			return props.mainMenu.menuTitleEs
		}
	}
	
	


	  
  const zoolinks = props.subMenu.map((oneitem, index) => {
	//	console.log('image ',oneitem.common_name_slug);
	  if(oneitem.common_name_slug === "sweet_water_fish") {
			return
		}
		return (
			<li key={index} className="guest-submenu-item">
			
			<img className="image-icon" src={`icons/${oneitem.common_name_slug}.jpg`}/>
			<Link className="image-link"
				to={`/${props.mainMenu.apiName}/${oneitem.common_name_slug}`}
				onClick={() => {
					props.updateActiveRoute(`/${props.mainMenu.apiName}/${oneitem.common_name_slug}`);
				}}
			>
				{props.activeLang === 'en' ? oneitem.common_name: oneitem.common_name_spanish} ({oneitem.scientific_name})
			
				</Link> 
		</li>
			
		)
  }
  )


	return (
		<div id="menu-div" onClick={(e) => props.onClick(e)}  className="image-button"  style={{cursor: "pointer"}}>

			<div className="square-container image-button__container">
				<div
					className="square-container image-button__img-container"
					style={{backgroundImage: `url('${props.mainMenu.bg}')`}}
				>
				</div>
			
			
			</div>
			<span className="image-button__text-container p-top__large mobile-p-top__x-large">
				{getText()}
			</span>
			{/* populate menus in loop above*/}
			
			<GuestMenuComponent zoolinks={zoolinks} />
		</div>
	)
}

export default ImageText

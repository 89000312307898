import React, {useState} from 'react'

import Section from '../components/Section'
import Row from '../components/Row'
import Column from '../components/Column'
import Button from '../components/Button'

import {en, es} from '../languages/langs'
import {api, sanctum} from '../services/api'


const Login = ({setLoggedInState, loggedIn, activeLang, activeUser}) => {
	const lang = {en, es}[activeLang]
	const [forgotPass, setForgotPass] = useState(false)

	const doLogin = async () => {
		let u = document.getElementById('username').value,
			p = document.getElementById('password').value

		await sanctum.get('/csrf-cookie')
		try {
			const response = await api.post(`/login`, {
				email: u,
				password: p
			})
			localStorage.setItem('accessToken', response.data.token)
			if (response.data.success) {
				setLoggedInState(true)
				const accessToken = response.data.token;				
				if(accessToken){
					api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
				}					
			}
		} catch (e) {
			if (e.response.status === 403) {
					alert(lang['login failed'])
					return
			}
		}
	}

	const doForgotPassword = async () => {
		if (!forgotPass) {
			setForgotPass(true)
			return
		}
		let e = document.getElementById('email').value
		await sanctum.get('/csrf-cookie')
		try {
			const response = await api.post('forgot-password', {
				email: e,
				language: activeLang
			})
			alert(response.data.status)
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<React.Fragment>
		{!loggedIn && 
			<Section extraClasses="content-width center mobile-p-left__large mobile-p-right__large">
				<Row>
					<Column extraClasses="size__one-of-one text--centered">
						<h3 className="m-top__large m-bottom__small">{lang['Welcome to International Zoo Services']}</h3>
						{!forgotPass &&
							<span className="sub-title sub-title--follows-h3 browngrey-text green-text">
								{lang['Please provide your credentials to log in as an existing user']}
							</span>
						}
					</Column>
				</Row>
				{!forgotPass &&
					<>
					<Row>
						<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
						<Column extraClasses="size__one-of-two m-top__x-large m-bottom__large mobile-m-top__none mobile-m-bottom__small">
							<input
								type="text"
								className="login-form__input login-form__input--mandatory"
								placeholder={`${lang['Username']}*`}
								id="username"
							/>
						</Column>
						<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					</Row>
					<Row>
						<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
						<Column extraClasses="size__one-of-two m-bottom__x-large">
							<input
								type="password"
								className="login-form__input login-form__input--mandatory"
								placeholder={`${lang['Password']}*`}
								id="password"
							/>
						</Column>
						<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					</Row>
				</>
				}
				{forgotPass &&
					<Row>
						<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
						<Column extraClasses="size__one-of-two m-top__x-large m-bottom__large mobile-m-top__none mobile-m-bottom__small">
							<input
								type="text"
								className="login-form__input login-form__input--mandatory"
								placeholder="E-mail"
								id="email"
							/>
						</Column>
						<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					</Row>
				}
				<Row>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
					<Column extraClasses="size__one-of-two m-bottom__x-large">
						{!forgotPass &&
							<Button onClick={doLogin} extraClasses="m-right__small">{lang['Login']}</Button>
						}
						<Button onClick={doForgotPassword}>
							{!forgotPass &&
								lang['Forgot Password']
							}
							{forgotPass &&
								lang['Get new password']
							}
						</Button>
					</Column>
					<Column extraClasses="size__three-of-twelve mobile-display__none">&nbsp;</Column>
				</Row>
			</Section>
		}
		{loggedIn && 
			<Section extraClasses="content-width center">
				<Row>
					<Column extraClasses="m-top__large size__one-of-one text--centered">
						<h4>{lang["Welcome"]} {activeUser.name}, {lang['Check out our animals']}</h4>
					</Column>
				</Row>
			</Section>
		}
		</React.Fragment>	
	)
}

export default Login
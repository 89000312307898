import React from 'react'

const GuestMenuComponent = props => {


  return (
    <div  className='guest-submenu'>
			<ul className={`onecolumn ${props.zoolinks.length > 15 ? ' species-submenu--2-column' : ''}`}>
								{props.zoolinks}
					
			</ul>
	</div>
  )
}
export default GuestMenuComponent